import React, { useMemo } from 'react';
import './style.scss';
import { AppStats } from '../../utils/appTypes';
import Text from '../Text';

interface Props {
  stats: AppStats[];
}

const Stats: React.FC<Props> = ({ stats }) => {
  const StatsItems = useMemo(
    () =>
      stats.map((item) => (
        <div className="stats__section" key={item.title}>
          <Text type="label" className="stats__title">
            {item.title}
          </Text>
          <Text type="label" className="stats__value">
            {item.value}
          </Text>
        </div>
      )),
    [stats]
  );

  return <div className="stats">{StatsItems}</div>;
};

export default Stats;
