import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const KeycloakIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        d="M50 100.5c27.614 0 50-22.386 50-50S77.614.5 50 .5 0 22.886 0 50.5s22.386 50 50 50Z"
      />
      <path
        fill="#fff"
        d="M76.777 39.123h-7.87a.414.414 0 0 1-.362-.205l-6.318-10.957a.43.43 0 0 0-.371-.205H36.073a.414.414 0 0 0-.361.205L29.14 39.328l-6.328 10.957a.455.455 0 0 0 0 .42l6.328 10.957 6.562 11.377a.395.395 0 0 0 .361.205h25.791a.443.443 0 0 0 .372-.205l6.328-10.957a.395.395 0 0 1 .361-.205h7.871a.469.469 0 0 0 .469-.469V39.602a.493.493 0 0 0-.479-.479ZM46.631 62.082 44.648 65.5a.395.395 0 0 1-.127.127.405.405 0 0 1-.185.049H40.4a.346.346 0 0 1-.322-.195L34.21 55.295l-.576-1.006-2.11-3.604a.262.262 0 0 1-.039-.175c0-.059.02-.127.05-.176l2.118-3.672 6.436-11.133a.387.387 0 0 1 .322-.195h3.926c.068 0 .137.02.205.049.049.03.098.068.127.127l1.982 3.437a.386.386 0 0 1-.02.371l-6.357 11.016a.309.309 0 0 0-.039.156c0 .059.02.108.04.157l6.357 11.005a.402.402 0 0 1 0 .43Zm19.736-11.397-2.11 3.604-.575 1.006-5.87 10.186a.374.374 0 0 1-.322.195h-3.935a.405.405 0 0 1-.186-.049.318.318 0 0 1-.127-.127l-1.982-3.418a.37.37 0 0 1-.01-.41l6.357-11.006a.31.31 0 0 0 .04-.156.408.408 0 0 0-.04-.157L51.25 39.338a.387.387 0 0 1-.02-.371l1.983-3.438a.357.357 0 0 1 .332-.176h3.945c.137 0 .264.069.322.196l6.436 11.133 2.12 3.671c.029.06.048.118.048.176a.4.4 0 0 1-.049.156Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(KeycloakIcon);
