import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ExpoIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000020"
      d="M47.386 38.458c.818-1.205 1.713-1.358 2.44-1.358.726 0 1.936.153 2.754 1.358 6.446 8.854 17.086 26.492 24.935 39.502 5.119 8.485 9.05 15.002 9.857 15.831 3.028 3.113 7.18 1.173 9.593-2.358C99.341 87.956 100 85.515 100 82.91c0-1.774-34.416-65.782-37.882-71.11-3.333-5.124-4.419-6.418-10.124-6.418h-4.269c-5.688 0-6.51 1.294-9.843 6.419C34.416 17.13 0 81.137 0 82.91c0 2.604.66 5.045 3.035 8.522 2.413 3.531 6.566 5.471 9.593 2.358.807-.83 4.738-7.346 9.857-15.83C30.334 64.95 40.94 47.311 47.386 38.457Z"
    />
  </svg>
);

export default React.memo(ExpoIcon);
