import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const GraphQLIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="m58.202 13.31 19.734 11.35c3.267-3.421 8.703-3.561 12.141-.31a8.829 8.829 0 0 1 1.5 1.895c2.358 4.09.94 9.313-3.189 11.66a8.537 8.537 0 0 1-2.235.886v22.683c4.593 1.09 7.422 5.66 6.327 10.23a8.763 8.763 0 0 1-.92 2.3c-2.377 4.075-7.609 5.472-11.719 3.111a8.177 8.177 0 0 1-2.094-1.695L58.121 86.69c1.485 4.463-.954 9.28-5.439 10.758a8.392 8.392 0 0 1-2.688.436c-4.719 0-8.562-3.794-8.562-8.505 0-.808.126-1.632.36-2.426L22.043 75.62c-3.267 3.406-8.688 3.546-12.108.28a8.503 8.503 0 0 1-.282-12.032 8.684 8.684 0 0 1 4.188-2.41V38.777c-4.593-1.09-7.437-5.69-6.327-10.278a8.376 8.376 0 0 1 .906-2.253c2.358-4.075 7.608-5.472 11.703-3.11a8.516 8.516 0 0 1 1.923 1.492l19.75-11.334c-1.345-4.507 1.25-9.25 5.765-10.573a8.068 8.068 0 0 1 2.44-.358c4.718 0 8.561 3.794 8.561 8.49 0 .838-.126 1.662-.36 2.456v.003Zm-2.06 3.5c-.079.077-.142.154-.235.232l25.845 44.527c.108-.03.234-.062.327-.092V38.764c-4.578-1.12-7.359-5.737-6.219-10.292.015-.093.048-.203.063-.296L56.141 16.81Zm-12.064.232-.234-.233-19.782 11.35c1.314 4.507-1.314 9.22-5.844 10.524-.108.03-.204.048-.312.078v22.713l.345.092L44.096 17.04l-.018.003Zm8.313 2.02a8.854 8.854 0 0 1-4.767 0L21.78 63.59a8.703 8.703 0 0 1 2.406 4.152h51.642a8.593 8.593 0 0 1 2.406-4.167L52.391 19.063Zm3.936 64.581 19.656-11.3c-.063-.189-.108-.374-.156-.562H24.17l-.093.325 19.767 11.35A8.542 8.542 0 0 1 50 80.857a8.527 8.527 0 0 1 6.327 2.785Z"
    />
  </svg>
);

export default React.memo(GraphQLIcon);
