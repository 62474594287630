import { useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';

export type SliderHookReturn = ReturnType<typeof useSlider>;

const useSlider = (loopSlides?: boolean, lastIndex?: number) => {
  const [slideIndex, setSliderIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const onChangeSliderIndex = useCallback<
    (current: number, next: number) => void
  >((_, next) => setSliderIndex(next), []);

  const onSlickPrevious = useCallback(() => {
    if (loopSlides && slideIndex === 0) {
      sliderRef.current?.slickGoTo(lastIndex ?? 0);
    } else {
      sliderRef.current?.slickPrev();
    }
  }, [lastIndex, loopSlides, slideIndex]);
  const onSlickNext = useCallback(() => {
    if (loopSlides && slideIndex === lastIndex) {
      sliderRef.current?.slickGoTo(0);
    } else {
      sliderRef.current?.slickNext();
    }
  }, [lastIndex, loopSlides, slideIndex]);

  return {
    onChangeSliderIndex,
    onSlickNext,
    onSlickPrevious,
    slideIndex,
    sliderRef,
  };
};

export default useSlider;
