import React, { FC, memo } from 'react';
import './style.scss';
import Text from '../Text';
import DoubleArrowIcon from '../icons/DoubleArrowIcon';
import ServiceTitle from '../ServiceTitle';
import { IconColor } from '../../utils/colorTypes';

interface Props {
  title: string;
  subtitle: string;
  color?: IconColor;
  className?: string;
  isSubtitleOnlyOnMobile?: boolean;
}

const SliderHeader: FC<Props> = ({
  title,
  subtitle,
  color = 'dark',
  className,
  isSubtitleOnlyOnMobile = false,
}) => {
  return (
    <div className={className}>
      <ServiceTitle color={color} className="slider-header-service__title">
        {title}
      </ServiceTitle>
      <div
        className={`slider-header-service__subtitle ${isSubtitleOnlyOnMobile ? 'slider-header-service__subtitle__mobile' : ''}`}
      >
        <Text type="p" color={color}>
          {subtitle}
        </Text>
        <DoubleArrowIcon color={color} />
      </div>
    </div>
  );
};

export default memo(SliderHeader);
