import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const FastlaneIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      d="M83.12 27.53c-4.03 0-7.68 1.624-10.36 4.258l-17.004-12.34a6.114 6.114 0 0 0-.435-4.049 5.998 5.998 0 0 0-2.874-2.848 5.888 5.888 0 0 0-4.005-.352 5.96 5.96 0 0 0-3.314 2.305 6.098 6.098 0 0 0 .473 7.654 5.934 5.934 0 0 0 3.572 1.869 5.896 5.896 0 0 0 3.93-.855l20.81 15.351c1.627-3.43 5.202-6.1 9.207-6.1a9.99 9.99 0 0 1 6.527 2.427 10.237 10.237 0 0 1 3.418 6.135 10.316 10.316 0 0 1-1.32 6.917 10.094 10.094 0 0 1-5.43 4.401L87.683 57a14.935 14.935 0 0 0 8.11-6.46 15.269 15.269 0 0 0 2.027-10.25 15.154 15.154 0 0 0-5.027-9.127 14.786 14.786 0 0 0-9.673-3.633Z"
    />
    <path
      fill="#1B1B1F"
      d="M35.45 13.341a15.051 15.051 0 0 0 .847 11.127L19.864 36.962a5.763 5.763 0 0 0-3.863-.792 5.815 5.815 0 0 0-3.48 1.883 6 6 0 0 0-1.514 3.702 6.018 6.018 0 0 0 1.141 3.838 5.84 5.84 0 0 0 3.276 2.229c1.313.334 2.7.195 3.923-.394a5.896 5.896 0 0 0 2.784-2.837 6.04 6.04 0 0 0 .382-3.99l20.346-15.223c-2.684-2.568-4.085-6.769-2.897-10.578a10.03 10.03 0 0 1 4.18-5.487 9.743 9.743 0 0 1 6.678-1.422 9.834 9.834 0 0 1 5.992 3.32 10.15 10.15 0 0 1 2.448 6.479l4.74.1a15.027 15.027 0 0 0-3.555-9.637 14.56 14.56 0 0 0-8.854-4.982 14.419 14.419 0 0 0-9.913 2.058 14.84 14.84 0 0 0-6.228 8.112Z"
    />
    <path
      fill="#1B1B1F"
      d="M8.073 53.43a14.731 14.731 0 0 0 10.695 2.71l6.54 19.535c-.34.292-.657.62-.932 1a5.863 5.863 0 0 0 1.315 8.185c2.626 1.918 6.294 1.359 8.198-1.246a5.863 5.863 0 0 0-1.315-8.184 5.886 5.886 0 0 0-3.023-1.118l-7.864-24.105c-3.25 1.732-7.63 1.732-10.827-.598-4.436-3.238-5.43-9.43-2.22-13.833a9.88 9.88 0 0 1 11.004-3.557l1.583-4.463c-5.897-1.968-12.607-.045-16.44 5.21-4.75 6.511-3.28 15.673 3.286 20.464ZM84.28 76.464a14.804 14.804 0 0 0-7.166-8.576l6.387-19.968a5.918 5.918 0 0 0 2.541-.793 5.961 5.961 0 0 0 1.943-1.83 6.008 6.008 0 0 0 .732-5.168 5.973 5.973 0 0 0-2.958-3.48 5.91 5.91 0 0 0-4.535-.355 5.934 5.934 0 0 0-2.03 1.132 6.012 6.012 0 0 0-1.82 6.391 5.924 5.924 0 0 0 1.814 2.71l-8.046 24.52c3.72.5 7.318 3.095 8.546 6.909a10.17 10.17 0 0 1-.636 7.711 10.062 10.062 0 0 1-5.866 5.001 10.008 10.008 0 0 1-6.172.017 10.072 10.072 0 0 1-5.022-3.611L58 89.81a14.904 14.904 0 0 0 7.45 5.454c2.993.98 6.218.981 9.212.003 7.829-2.544 12.135-10.963 9.619-18.803Z"
    />
    <path
      fill="#1B1B1F"
      d="M38.323 92.1c3.079-2.317 5.159-5.782 5.796-9.656l20.597-.216a5.987 5.987 0 0 0 2.672 3.012 5.715 5.715 0 0 0 3.918.577A5.844 5.844 0 0 0 74.69 83.7 6.171 6.171 0 0 0 76 79.847a6.167 6.167 0 0 0-1.36-3.833 5.838 5.838 0 0 0-3.41-2.072 5.717 5.717 0 0 0-3.911.632 5.995 5.995 0 0 0-2.633 3.048l-25.354.067c.678 3.748-.633 8.053-3.817 10.453a9.705 9.705 0 0 1-6.583 1.953 9.786 9.786 0 0 1-6.24-2.912 10.36 10.36 0 0 1-2.922-6.388 10.461 10.461 0 0 1 1.792-6.816L17.781 71a15.481 15.481 0 0 0-2.733 10.086c.282 3.59 1.797 6.959 4.272 9.501 2.475 2.542 5.748 4.09 9.231 4.368a14.363 14.363 0 0 0 9.772-2.856Z"
    />
  </svg>
);

export default React.memo(FastlaneIcon);
