import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ReactReanimatedIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      d="M37.371 79.727H33.91l-1.218-2.31v-1.679l-4.574-7.483V66.26l2.902-6.214v-2.222l-4.49-5.26-1.36-5.216v-3.356h-1.995c-2.177 1.197-5.715 1.92-7.211 2.132l-5.125 3.537-2.766-.453c-2.068 1.16-3.492 3.9-3.946 5.124l-2.313 2.086H0v-1.176l2.812-2.089V48.8l-.408-1.043 3.99-2.675c3.592-3.375 7.302-4.37 8.708-4.445H25.17c3.673-2.766 11.304-5.215 15.147-5.215 3.075 0 6.391.846 7.665 1.27h9.252l7.346-1.27 5.17-2.993-2.675-.272c.036-1.089 2.917-2.63 4.354-3.266l-2.495-.272 2.903-1.95 1.86-.227c.65-.423 2.158-1.324 2.992-1.542 1.053-2.975 4.006-3.235 5.352-2.993l2.131-1.406 1.497 1.406 3.628 1.678c.499.454 1.705 1.506 2.54 2.086.834.58 1.708 2.207 2.04 2.948l4.173 3.538 1.406 2.131-1.043 2.585-2.767.59c-1.36-1.044-3.492-1.36-4.852-1.678-1.089-.254-2.177-1.104-2.585-1.497h-3.402l-2.766 1.497-2.676 7.437c-.145 5.152-1.39 7.8-1.995 8.481l8.888 2.903 10.749 7.71.227 1.405 2.358 2.087.408 1.632-3.764.454-2.086-3.81-7.892-6.35H83.13L82.585 61l-3.447 4.434H77.37l-2.858 1.282V63.72l.771-.53h2.087l2.54-3.28v-2.176l-4.016-.89-5.256 1.282-3.205-.77-3.526 1.603-10.512-2.115-5-1.602-2.627.32 4.487 7.628 10.64 5.32v.769l3.012 1.218v.833l-3.012 1.026-9.423-5.641-5-1.282-6.409-8.589-1.795-.77-1.794 6.026-3.782 5.64v2.308l3.14 6.218 2.436 2.692-.897.769Z"
    />
  </svg>
);

export default React.memo(ReactReanimatedIcon);
