import React, { memo } from 'react';
import { TechIconItemProps } from '../TechIcon';

const KeystoneJSIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24.524 8.875c-8.919 0-16.148 7.23-16.148 16.149v50.952c0 8.919 7.23 16.148 16.148 16.148h50.952c8.919 0 16.149-7.23 16.149-16.148V25.024c0-8.919-7.23-16.149-16.149-16.149H24.524Zm6.756 20.03h10.602v18.864L58.27 28.904h11.154L53.863 47.77l16.249 24.786H56.617L46.565 55.618l-4.682 4.407v12.53H31.28v-43.65Z"
      clipRule="evenodd"
    />
  </svg>
);
export default memo(KeystoneJSIcon);
