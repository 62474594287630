import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const I18NextIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        d="M62.235 10.862v18.82l21.15 54.32s6.456 13.265-12.01 16.498H29.246S12.035 98.704 16.16 85.075l22.225-55.392v-18.82h23.85ZM62.682 8.674h-25.37c-2.055 0-3.727-1.828-3.727-4.086 0-2.26 1.671-4.088 3.727-4.088h25.37c2.064 0 3.733 1.828 3.733 4.088 0 2.258-1.67 4.086-3.733 4.086Z"
      />
      <path
        fill="#fff"
        d="M55.989 41.592c2.722 0 4.943 2.66 4.943 5.954 0 3.28-2.222 5.946-4.943 5.946-2.729 0-4.942-2.666-4.942-5.946 0-3.296 2.213-5.954 4.942-5.954ZM40.817 57.038c2.3 0 4.166-2.248 4.166-5.021 0-2.774-1.865-5.022-4.166-5.022s-4.166 2.248-4.166 5.022c0 2.773 1.865 5.021 4.166 5.021Z"
      />
      <path
        fill="#1B1B1F"
        d="M64.116 28.435v-8.95s11.351-2.504 17.024 10.7c0 0-8.84 6.44-17.024-1.75ZM36.314 28.435v-8.95s-11.352-2.504-17.022 10.7c0 0 8.832 6.44 17.022-1.75Z"
      />
      <path
        fill="#fff"
        d="M70.94 65.478c-18.458-5.726-33.976-2.652-41.561-.328l-7.65 18.41c-3.443 10.992 10.936 12.436 10.936 12.436H67.85c15.423-2.606 10.033-13.298 10.033-13.298l-6.942-17.22Z"
      />
      <path
        fill="#1B1B1F"
        d="M44.34 72.32c0 1.984-2.669 3.6-5.955 3.6s-5.946-1.616-5.946-3.6c0-1.994 2.66-3.603 5.946-3.603 3.288.001 5.954 1.609 5.954 3.602ZM65.513 72.32c0 1.984-2.668 3.6-5.946 3.6-3.295 0-5.954-1.616-5.954-3.6 0-1.994 2.659-3.603 5.954-3.603 3.28.001 5.946 1.609 5.946 3.602Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(I18NextIcon);
