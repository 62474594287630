import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const HasuraIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g fill="#1B1B1F" clipPath="url(#a)">
      <path d="M63.526 69.856 50.818 45.063a4.455 4.455 0 0 0-5.994-1.93 4.455 4.455 0 0 0-1.93 5.99L48.2 59.47l-9.498 9.471a4.455 4.455 0 0 0-.01 6.298c.87.87 2.01 1.308 3.15 1.308 1.14 0 2.276-.438 3.145-1.3l7.474-7.457 3.142 6.128a4.449 4.449 0 0 0 5.992 1.933 4.455 4.455 0 0 0 1.931-5.994Z" />
      <path d="M80.62.804c-2.535 1.61 1.745 16.29-8.41 24.95a40.778 40.778 0 0 0-22.261-6.594c-8.188 0-15.806 2.428-22.206 6.557-10.095-8.66-5.835-23.303-8.359-24.913-4.138-2.64-11.917 12.52-11.319 22.15.323 5.148 1.693 12.692 5.434 18.537-2.79 5.483-4.395 11.65-4.395 18.195 0 22.352 18.322 40.538 40.845 40.538 22.528 0 40.846-18.185 40.846-40.538 0-6.507-1.592-12.642-4.35-18.098 3.786-5.862 5.166-13.46 5.49-18.635.601-9.629-7.183-24.789-11.315-22.15ZM49.949 91.282c-17.553 0-31.843-14.168-31.843-31.596 0-17.42 14.29-31.594 31.843-31.594 17.563 0 31.844 14.175 31.844 31.594 0 17.428-14.282 31.596-31.844 31.596Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(HasuraIcon);
