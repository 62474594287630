import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const TypeScriptIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      d="M86.21 5.25H13.79A8.79 8.79 0 0 0 5 14.04v72.42a8.79 8.79 0 0 0 8.79 8.79h72.42A8.79 8.79 0 0 0 95 86.46V14.04a8.79 8.79 0 0 0-8.79-8.79Z"
    />
    <path
      fill="#1B1B1F"
      d="M86.21 5.25H13.79A8.79 8.79 0 0 0 5 14.04v72.42a8.79 8.79 0 0 0 8.79 8.79h72.42A8.79 8.79 0 0 0 95 86.46V14.04a8.79 8.79 0 0 0-8.79-8.79Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M60.712 76.868v8.8c1.43.733 3.122 1.282 5.076 1.65 1.953.366 4.011.55 6.176.55 2.109 0 4.112-.202 6.01-.606 1.899-.403 3.563-1.068 4.994-1.994a10.157 10.157 0 0 0 3.397-3.63c.835-1.494 1.252-3.34 1.252-5.54 0-1.596-.238-2.993-.715-4.194a9.8 9.8 0 0 0-2.063-3.204c-.899-.935-1.977-1.773-3.233-2.516a34.628 34.628 0 0 0-4.25-2.103 53.587 53.587 0 0 1-3.109-1.39c-.917-.449-1.696-.907-2.338-1.374-.642-.468-1.138-.963-1.486-1.485a3.125 3.125 0 0 1-.523-1.774c0-.605.156-1.15.468-1.636.312-.486.752-.903 1.32-1.251.57-.349 1.266-.62 2.091-.812.826-.192 1.743-.288 2.752-.288a18.384 18.384 0 0 1 4.787.674c.825.229 1.627.517 2.407.866.779.348 1.499.751 2.159 1.21v-8.223c-1.339-.513-2.801-.893-4.388-1.14-1.586-.248-3.407-.372-5.46-.372-2.092 0-4.072.225-5.943.674-1.87.449-3.517 1.15-4.938 2.103a10.511 10.511 0 0 0-3.37 3.644c-.825 1.476-1.238 3.24-1.238 5.294 0 2.621.756 4.858 2.27 6.71 1.512 1.851 3.81 3.418 6.89 4.702 1.211.495 2.34.98 3.385 1.457 1.045.477 1.948.972 2.71 1.485.76.513 1.361 1.073 1.801 1.678.44.605.66 1.292.66 2.062 0 .568-.137 1.095-.412 1.581-.275.486-.692.908-1.252 1.265-.56.358-1.256.637-2.09.839-.835.202-1.812.302-2.93.302-1.908 0-3.797-.334-5.668-1.003-1.87-.67-3.604-1.673-5.2-3.011ZM45.918 55.186h11.289v-7.221H25.742v7.221h11.234V87.34h8.942V55.186Z"
      clipRule="evenodd"
    />
  </svg>
);

export default React.memo(TypeScriptIcon);
