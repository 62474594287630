import { IGatsbyImageData } from 'gatsby-plugin-image';
import { TechVariants } from '../components/icons/TechIcon';
import { ClientFeedbackItem } from './generalTypes';

export enum AppNames {
  Asha = 'Asha',
  PlatoonFit = 'Platoon Fit',
  Humanoo = 'ERP for an InsurTech Startup',
  FitTracksWeb = 'Fit Tracks Web',
  FeelAmazing = 'Feel amazing',
  Bidroom = 'Bidroom',
  BSG = 'Back Seat Games',
  Holodomor = 'Track Holodomor History',
  FitTracks = 'FitTracks',
  CheckMyBus = 'CheckMyBus',
  Yangol = 'Yangol',
  NorskGuardian = 'Norsk Guardian',
  Deftpower = 'Deftpower',
  ForceUSA = 'Force USA',
  SportPlus = 'SportPlus',
  ArtOfComms = 'Art of Comms',
}

export enum ProductContentVariants {
  Label = 'label',
  Title = 'h2',
  Stats = 'expandedStats',
  Stages = 'stages',
  Image = 'image',
  List = 'list',
  Highlight = 'highlight',
  Perspective = 'perspective',
}

export interface AppStage {
  title: string;
  duration: string;
  items: string[];
}

export interface AppStats {
  title: string;
  value: string;
}

export interface AppPerspective {
  title: string;
  text?: string;
  clientFeedback?: ClientFeedbackItem;
}

export interface AppSEOData {
  seoTitle: string;
  seoDescription: string;
}

interface APIAppImageData {
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
}

interface AppProductContentImage {
  type: ProductContentVariants.Image;
  image: APIAppImageData;
}

interface AppProductContentLabel {
  type: ProductContentVariants.Label;
  children: string;
}

interface AppProductContentTitle {
  type: ProductContentVariants.Title;
  children: string;
}
interface AppProductContentList {
  type: ProductContentVariants.List;
  texts: string[];
}

interface AppProductContentStages {
  type: ProductContentVariants.Stages;
  stagesData: AppStage[];
}

export interface AppProductContentStats {
  type: ProductContentVariants.Stats;
  statsData: AppStats[];
}

interface AppProductContentHighlight {
  type: ProductContentVariants.Highlight;
  children: string;
}

interface AppProductContentPerspective {
  type: ProductContentVariants.Perspective;
  perspective: AppPerspective;
}

interface AppBlog {
  title: string;
  mainArticleDescription: string;
  articles: {
    slug: string;
    readTime: string;
  }[];
}

export interface AppStoreLinks {
  playStore?: string;
  appStore?: string;
}

interface AppFeedback {
  title: string;
  items: string[];
}

export type AppProductContent =
  | AppProductContentImage
  | AppProductContentLabel
  | AppProductContentTitle
  | AppProductContentStages
  | AppProductContentStats
  | AppProductContentList
  | AppProductContentHighlight
  | AppProductContentPerspective;

export interface AppType {
  title: AppNames;
  appKey: string;
  slug: string;
  techStack: TechVariants[];
  tags: string[];
  isInsideCarousel: boolean;
  description: string;
  _updatedAt: Date;
  _createdAt: Date;
  productContent: AppProductContent[];
  cover: APIAppImageData;
  thumbnail: APIAppImageData;
  moto: string;
  backgroundColor: string;
  seoData: AppSEOData;
  blog: AppBlog;
  feedback: AppFeedback;
  storeLinks: AppStoreLinks;
}
