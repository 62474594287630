import React, { memo, useMemo } from 'react';
import Text from '../Text';
import './style.scss';
import PolygonIcon from '../icons/Polygon';
import colors from '../../theme/colors';
import { AppPerspective } from '../../utils/appTypes';
import CardComment from '../Comments/CardUpdated';

interface Props {
  content: AppPerspective;
}

const Perspective: React.FC<Props> = ({
  content: { title, text, clientFeedback },
}) => {
  const Texts = useMemo(
    () =>
      text?.split('\n').map((line, index) => (
        <Text type="label" className="perspective__text" key={index}>
          {line}
        </Text>
      )),
    [text]
  );

  return (
    <div className="perspective">
      <div className="perspective__header">
        <PolygonIcon color={colors.main.accentColor.SECONDARY} />
        <Text type="h3">{title}</Text>
      </div>
      {Texts}
      {clientFeedback && <CardComment {...clientFeedback} isSingular />}
    </div>
  );
};

export default memo(Perspective);
