import * as React from 'react';
import './style.scss';
import Text from '../Text';
import { ClientFeedbackItem } from '../../utils/generalTypes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import lazyframe from 'lazyframe';
import Button from '../Button';
import classNames from 'classnames';

export interface Props extends ClientFeedbackItem {
  id: string;
  isSingular?: boolean;
}

const CardComment: React.SFC<Props> = ({
  authorImage,
  authorName,
  authorPosition,
  body,
  caseStudyLink,
  image,
  videoLink,
  id,
  isSingular,
}) => {
  const { t } = useTranslation();
  const frameId = `lazyframe-${id}`;

  useEffect(() => {
    if (videoLink) {
      lazyframe(`.${frameId}`, {
        lazyload: true,
        debounce: isSingular ? 0 : 200,
      });
    }
  }, [frameId, isSingular, videoLink]);

  const ProjectVisual = useMemo(() => {
    if (videoLink) {
      return (
        <div
          data-src={videoLink}
          data-vendor="youtube"
          className={`card__visual ${frameId} lazyframe`}
        />
      );
    }

    const imageAsset = getImage(image?.asset);

    return (
      <GatsbyImage image={imageAsset!} alt={''} className="card__visual" />
    );
  }, [frameId, image?.asset, videoLink]);

  const AuthorImage = useMemo(() => {
    const imageAsset = getImage(authorImage?.asset);

    return (
      <GatsbyImage
        image={imageAsset!}
        alt={authorName}
        className="card__image"
      />
    );
  }, [authorImage?.asset, authorName]);

  return (
    <div className={classNames('card', { card__singular: isSingular })}>
      <div
        className={classNames('card-visual', {
          'card-visual__singular': isSingular,
        })}
      >
        {ProjectVisual}
      </div>

      <div
        className={classNames('card__comment', {
          card__comment__singular: isSingular,
        })}
      >
        <div
          className={classNames('feedback-card-author', {
            'feedback-card-author__singular': isSingular,
          })}
        >
          {AuthorImage}
          <div
            className={classNames('feedback-card-author__body', {
              'feedback-card-author__body__singular': isSingular,
            })}
          >
            <Text
              type="p"
              color="dark"
              weight="medium"
              className={'feedback-card-author__name h5'}
            >
              {authorName}
            </Text>
            <Text
              type="p"
              color="dark"
              className={'card__credentials__link h6'}
            >
              {authorPosition}
            </Text>
          </div>
        </div>
        {!isSingular && (
          <>
            <Text
              type="p"
              color="dark"
              weight="medium"
              className={'card__body p-updated'}
            >
              {body}
            </Text>
            <Button
              size="regular"
              href={caseStudyLink}
              rounded
              color="dark-grey"
              className="card__button"
            >
              {t('case-study')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CardComment;
