import React from 'react';
import './style.scss';
import classNames from 'classnames';
import Link from '../Link';

export type CategoryStyle =
  | 'light'
  | 'green'
  | 'orange'
  | 'grey'
  | 'dark-grey'
  | 'light-grey';

interface Props {
  style?: CategoryStyle;
  isWide?: boolean;
  href?: string;
  isCapital?: boolean;
  className?: string;
}

const Category: React.FC<Props> = ({
  style = 'light',
  isWide,
  isCapital,
  href,
  className,
  children,
}) => {
  const categoryClass = classNames(
    'category',
    `category--${style}`,
    { 'category--wide': isWide },
    { 'category--capital': isCapital },
    className
  );
  return href ? (
    <Link href={href} className={categoryClass}>
      {children}
    </Link>
  ) : (
    <span className={categoryClass}>{children}</span>
  );
};

export default Category;
