import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const NodeJsIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M45.883 2.252c2.487-1.4 5.727-1.406 8.212 0C66.6 9.195 79.107 16.126 91.61 23.073c2.351 1.302 3.924 3.873 3.9 6.535v41.77c.017 2.773-1.71 5.406-4.192 6.678-12.462 6.904-24.919 13.817-37.38 20.721-2.538 1.427-5.843 1.317-8.303-.227-3.736-2.128-7.479-4.246-11.216-6.372-.763-.447-1.624-.803-2.163-1.531.477-.631 1.329-.71 2.021-.986 1.56-.487 2.992-1.27 4.425-2.028.362-.244.805-.15 1.152.068 3.195 1.8 6.362 3.652 9.567 5.435.684.388 1.377-.127 1.961-.447 12.23-6.792 24.475-13.559 36.702-20.353.454-.214.704-.687.667-1.174.009-13.78.002-27.56.004-41.34.052-.553-.274-1.061-.783-1.288-12.42-6.873-24.832-13.756-37.25-20.63A1.31 1.31 0 0 0 49.26 7.9C36.84 14.778 24.43 21.668 12.013 28.541c-.507.226-.848.726-.787 1.282.002 13.779 0 27.56 0 41.34-.022.239.031.478.152.685.12.207.303.373.523.475 3.313 1.847 6.631 3.68 9.947 5.52 1.868.989 4.162 1.575 6.22.819 1.816-.64 3.089-2.462 3.054-4.357.018-13.698-.008-27.399.013-41.095-.045-.608.542-1.11 1.143-1.053a212.03 212.03 0 0 1 4.78.004c.664-.015 1.121.64 1.039 1.253-.007 13.785.017 27.57-.01 41.356.003 3.674-1.533 7.671-4.991 9.469-4.26 2.168-9.526 1.709-13.735-.371-3.644-1.787-7.121-3.896-10.7-5.81-2.488-1.266-4.207-3.91-4.19-6.68v-41.77c-.026-2.717 1.61-5.335 4.034-6.618 12.46-6.91 24.919-13.825 37.378-20.738Z"
    />
    <path
      fill="#000"
      d="M56.753 31.186c5.434-.344 11.252-.204 16.143 2.427 3.786 2.016 5.885 6.247 5.952 10.38-.106.558-.699.866-1.24.828-1.577-.003-3.154.02-4.731-.011-.669.025-1.058-.58-1.142-1.162-.453-1.978-1.55-3.936-3.445-4.89-2.908-1.431-6.28-1.359-9.45-1.33-2.316.122-4.805.319-6.766 1.656-1.506 1.013-1.963 3.06-1.426 4.706.507 1.183 1.896 1.565 3.033 1.917 6.55 1.683 13.49 1.515 19.914 3.73 2.66.904 5.262 2.659 6.172 5.396 1.191 3.667.669 8.05-1.986 10.995-2.154 2.423-5.29 3.742-8.419 4.458-4.161.912-8.48.935-12.706.53-3.974-.445-8.11-1.47-11.177-4.132-2.623-2.238-3.905-5.725-3.777-9.091.03-.569.606-.965 1.16-.918 1.588-.013 3.176-.017 4.764.002.634-.045 1.104.494 1.137 1.081.293 1.884 1.013 3.862 2.686 4.98 3.227 2.045 7.276 1.905 10.971 1.963 3.061-.134 6.498-.174 8.996-2.163 1.318-1.134 1.709-3.031 1.353-4.663-.386-1.378-1.853-2.02-3.113-2.44-6.466-2.01-13.483-1.28-19.886-3.553-2.6-.903-5.113-2.61-6.112-5.234-1.393-3.714-.755-8.308 2.179-11.153 2.86-2.83 6.99-3.919 10.916-4.309Z"
    />
  </svg>
);

export default React.memo(NodeJsIcon);
