import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ESLintIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#7A7A7B"
      d="m30.483 38.557 18.758-10.83a1.517 1.517 0 0 1 1.518 0l18.758 10.83c.47.271.759.772.759 1.314v21.66a1.52 1.52 0 0 1-.759 1.315L50.76 73.676a1.517 1.517 0 0 1-1.518 0l-18.758-10.83a1.518 1.518 0 0 1-.759-1.314v-21.66c0-.543.29-1.044.759-1.315Z"
    />
    <path
      fill="#1B1B1F"
      d="M98.772 48.423 76.359 9.43c-.814-1.41-2.318-2.45-3.946-2.45H27.587c-1.628 0-3.132 1.04-3.947 2.45L1.229 48.337a4.637 4.637 0 0 0 0 4.6L23.64 91.609c.815 1.41 2.319 2.13 3.947 2.13h44.826c1.628 0 3.132-.699 3.946-2.109L98.77 52.895a4.397 4.397 0 0 0 0-4.472Zm-18.559 18.75c0 .573-.345 1.103-.842 1.39L50.822 85.034a1.624 1.624 0 0 1-1.615 0l-28.57-16.471c-.497-.287-.845-.817-.845-1.39V34.23c0-.574.343-1.104.84-1.39l28.547-16.472a1.621 1.621 0 0 1 1.614 0l28.573 16.471c.497.287.847.817.847 1.39v32.944Z"
    />
  </svg>
);

export default React.memo(ESLintIcon);
