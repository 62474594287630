import React, { FC, memo } from 'react';

import './style.scss';

import TechIcon, { TechVariants } from '../icons/TechIcon';
import Text from '../Text';
import useScreenMode from '../../hooks/useScreenMode';
import classNames from 'classnames';

interface Props {
  techStack: TechVariants[];
}

const ProductTechStack: FC<Props> = ({ techStack }) => {
  const screenMode = useScreenMode();

  return (
    <section className="product__tech-stack">
      <Text type="h2" className="product__tech-stack__title">
        Tech stack
      </Text>
      <div className="product__tech-stack__container">
        <ul className="product__tech-stack__list">
          {techStack.map((item) => (
            <div key={item} className="product__tech-stack__item-container">
              <li
                className={classNames('product__tech-stack__list__item', {
                  'product__tech-stack__list__item_small':
                    screenMode === 'mobile',
                })}
              >
                <TechIcon
                  variant={item}
                  size={screenMode === 'mobile' ? 'small' : 'large'}
                />
              </li>
              <Text type="label">{item}</Text>
            </div>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default memo(ProductTechStack);
