import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const HygraphIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        d="m63.2 13.9-11.8 6.6-11.8 6.6-11.8 6.6v40l11.8-6.6 11.8-6.6V47.1l-11.8 6.6V40.5l11.8-6.6 11.8-6.6v40l-11.8 6.6-11.8 6.6-11.8 6.6L16 93.9l11.8 6.6 11.8-6.6 11.8-6.6 11.8-6.6L75 74.1v-67L63.2.5v13.4Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(HygraphIcon);
