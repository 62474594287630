import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ZodisIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        d="M84.288 12 100 39.778H82.448L70.434 12h13.854Zm-22.222 0L74.08 39.778H25.92L37.934 12h24.132Zm-46.354 0h13.854L17.552 39.778H0L15.712 12ZM0 45.333h17.483l21.354 43.698c.26.538-.469 1.024-.868.573L0 45.334Zm25.73 0h48.54l-23.784 55.243c-.173.417-.781.417-.955 0L25.73 45.333Zm35.433 43.698 21.354-43.698H100L62.031 89.587c-.4.468-1.128-.018-.868-.556Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(ZodisIcon);
