import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ReduxIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M67.637 64.22c3.374-.349 5.933-3.258 5.817-6.748-.116-3.49-3.025-6.284-6.516-6.284h-.232c-3.607.117-6.4 3.142-6.283 6.749.116 1.745.814 3.258 1.861 4.305-3.956 7.796-10.006 13.497-19.08 18.268-6.168 3.258-12.567 4.421-18.966 3.607-5.236-.698-9.308-3.025-11.868-6.865-3.723-5.701-4.072-11.868-.93-18.035 2.21-4.422 5.7-7.68 7.911-9.309-.465-1.512-1.163-4.072-1.512-5.933C.968 56.192 2.713 72.715 7.833 80.51c3.84 5.818 11.635 9.425 20.245 9.425 2.326 0 4.653-.233 6.98-.815 14.894-2.909 26.18-11.752 32.579-24.9Zm20.477-14.428C79.272 39.437 66.24 33.736 51.348 33.736h-1.862c-1.047-2.095-3.258-3.491-5.701-3.491h-.233c-3.606.116-6.399 3.141-6.283 6.748.117 3.49 3.025 6.283 6.516 6.283h.232c2.56-.116 4.77-1.745 5.702-3.956h2.094c8.843 0 17.22 2.56 24.783 7.563 5.817 3.84 10.006 8.843 12.333 14.894 1.978 4.887 1.861 9.657-.233 13.73-3.258 6.166-8.726 9.54-15.94 9.54-4.654 0-9.075-1.396-11.402-2.443-1.28 1.164-3.607 3.025-5.236 4.19C61.12 89.12 66.24 90.4 71.128 90.4c11.169 0 19.43-6.167 22.571-12.334 3.374-6.748 3.141-18.384-5.585-28.274ZM29.008 66.198c.117 3.49 3.025 6.283 6.516 6.283h.233c3.607-.116 6.399-3.142 6.282-6.748-.116-3.491-3.025-6.284-6.515-6.284h-.233c-.232 0-.582 0-.814.117-4.77-7.913-6.748-16.522-6.05-25.83.465-6.982 2.792-13.033 6.864-18.036 3.374-4.305 9.89-6.4 14.312-6.516C61.934 8.952 67.17 24.31 67.52 30.477c1.513.35 4.072 1.164 5.818 1.746C71.94 13.373 60.306 3.6 49.138 3.6c-10.473 0-20.13 7.563-23.97 18.733-5.351 14.893-1.86 29.204 4.655 40.49-.582.815-.931 2.095-.815 3.375Z"
    />
  </svg>
);

export default React.memo(ReduxIcon);
