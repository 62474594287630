import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const PostgressIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        stroke="#1B1B1F"
        strokeWidth={8.719}
        d="M73.923 73.297c.636-5.298.445-6.076 4.392-5.217l1.002.088c3.035.138 7.006-.488 9.338-1.572 5.02-2.33 7.998-6.22 3.047-5.197-11.293 2.33-12.07-1.495-12.07-1.495 11.925-17.693 16.91-40.153 12.608-45.65C80.504-.742 60.189 6.349 59.85 6.533l-.11.02c-2.23-.463-4.728-.74-7.533-.785-5.11-.084-8.988 1.34-11.93 3.57 0 0-36.24-14.93-34.554 18.777.359 7.17 10.278 54.258 22.11 40.036 4.323-5.201 8.502-9.598 8.502-9.598 2.075 1.378 4.56 2.082 7.165 1.829l.202-.172c-.063.646-.036 1.277.08 2.025-3.047 3.406-2.152 4.003-8.245 5.258-6.165 1.27-2.543 3.532-.179 4.124 2.867.716 9.499 1.732 13.98-4.541l-.178.716c1.196.956 1.115 6.874 1.285 11.103.17 4.228.452 8.175 1.314 10.501.862 2.326 1.88 8.319 9.888 6.602 6.693-1.434 11.81-3.498 12.276-22.7Z"
      />
      <path
        fill="#1B1B1F"
        d="M91.703 61.398c-11.294 2.33-12.07-1.494-12.07-1.494 11.925-17.695 16.91-40.155 12.608-45.652C80.505-.743 60.189 6.349 59.85 6.532l-.11.02c-2.231-.463-4.728-.739-7.535-.785-5.11-.083-8.987 1.34-11.928 3.57 0 0-36.241-14.93-34.556 18.778.359 7.17 10.278 54.258 22.11 40.035 4.324-5.2 8.502-9.598 8.502-9.598 2.075 1.38 4.56 2.082 7.164 1.83l.202-.172c-.063.646-.034 1.277.081 2.025-3.047 3.405-2.152 4.003-8.245 5.257-6.165 1.271-2.543 3.533-.178 4.124 2.866.717 9.498 1.733 13.98-4.54l-.179.715c1.194.957 2.033 6.222 1.892 10.995-.14 4.773-.234 8.05.707 10.61.94 2.56 1.879 8.319 9.889 6.602 6.692-1.434 10.16-5.15 10.643-11.35.343-4.408 1.117-3.757 1.166-7.697l.622-1.866c.716-5.975.114-7.902 4.237-7.006l1.002.088c3.035.138 7.007-.488 9.338-1.572 5.02-2.33 7.998-6.219 3.048-5.197Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.906}
        d="M49.823 64.823c-.31 11.118.078 22.313 1.166 25.034 1.089 2.72 3.418 8.013 11.429 6.297 6.692-1.435 9.127-4.212 10.184-10.34.778-4.509 2.278-17.031 2.47-19.597M40.222 9.09S3.956-5.734 5.642 27.973c.359 7.17 10.279 54.26 22.11 40.036 4.323-5.2 8.233-9.28 8.233-9.28M59.81 6.384c-1.255.394 20.174-7.833 32.352 7.728 4.302 5.497-.683 27.957-12.608 45.652"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth={2.906}
        d="M79.554 59.764s.777 3.825 12.072 1.494c4.948-1.022 1.97 2.868-3.049 5.199-4.119 1.912-13.353 2.401-13.504-.24-.388-6.816 4.86-4.745 4.481-6.453Zm0 0c-.342-1.538-2.69-3.047-4.242-6.811-1.355-3.286-18.59-28.481 4.78-24.74.856-.177-6.095-22.228-27.966-22.586-21.865-.359-21.148 26.89-21.148 26.89"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.906}
        d="M43.702 62.095c-3.049 3.405-2.152 4.003-8.245 5.258-6.166 1.27-2.543 3.532-.18 4.123 2.867.718 9.5 1.733 13.98-4.542 1.366-1.91-.007-4.959-1.882-5.736-.906-.375-2.117-.845-3.673.897Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.906}
        d="M43.502 62.035c-.307-2.002.658-4.384 1.692-7.171 1.554-4.182 5.14-8.365 2.271-21.63-2.138-9.887-16.479-2.058-16.488-.718-.009 1.34.649 6.794-.24 13.146-1.158 8.288 5.274 15.297 12.682 14.58"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.969}
        d="M40.09 32.315c-.065.458.838 1.68 2.015 1.843 1.176.164 2.182-.79 2.245-1.248.064-.458-.838-.962-2.015-1.126-1.176-.164-2.182.075-2.245.531Z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.484}
        d="M75.885 31.382c.064.458-.838 1.68-2.016 1.843-1.175.164-2.181-.791-2.246-1.248-.062-.458.84-.962 2.016-1.126 1.177-.164 2.182.074 2.246.531Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.906}
        d="M80.091 28.213c.194 3.591-.773 6.037-.895 9.86-.18 5.557 2.649 11.917-1.615 18.285"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(PostgressIcon);
