import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const PrismaIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      fillRule="evenodd"
      d="M82.521 79.279 38.488 92.302c-1.345.398-2.635-.765-2.352-2.122l15.73-75.336c.295-1.408 2.242-1.632 2.857-.327l29.126 61.85c.55 1.167-.077 2.543-1.328 2.912Zm7.552-3.072L56.348 4.592v-.001a5.41 5.41 0 0 0-4.61-3.081c-2.053-.12-3.892.87-4.929 2.555l-36.575 59.24a5.54 5.54 0 0 0 .064 5.947l17.879 27.696c1.065 1.653 2.912 2.61 4.83 2.61.544 0 1.091-.077 1.63-.236l51.896-15.35a5.686 5.686 0 0 0 3.57-3.085 5.566 5.566 0 0 0-.03-4.68Z"
      clipRule="evenodd"
    />
  </svg>
);

export default React.memo(PrismaIcon);
