import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const FirebaseAnalyticsIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M58.981 31.547 51.875 17.95A2.125 2.125 0 0 0 50 16.816c-.81 0-1.512.453-1.869 1.122l-.006.012L16.922 73.87l42.06-42.322Zm24.932 47.71-8.788-54.672a2.114 2.114 0 0 0-2.084-1.785c-.59 0-1.122.24-1.507.631L16.084 79.26l30.679 17.287c.893.51 1.962.813 3.1.813 1.137 0 2.209-.3 3.13-.829l-.03.016 30.95-17.29ZM18.328 64.84l9.235-59.4a2.122 2.122 0 0 1 2.093-1.803c.807 0 1.51.45 1.866 1.115l.006.013 9.931 18.631-23.13 41.444Z"
    />
    <path
      fill="#7A7A7B"
      d="M78.463 92.772c9.162 0 16.59-7.447 16.59-16.634 0-9.186-7.428-16.633-16.59-16.633-9.162 0-16.59 7.447-16.59 16.633 0 9.187 7.428 16.634 16.59 16.634Z"
    />
    <path
      fill="#fff"
      d="M85.57 68.178h-4.332a.366.366 0 0 0-.365.366v15.19c0 .201.163.365.365.365h4.332a.366.366 0 0 0 .366-.365v-15.19a.366.366 0 0 0-.366-.366ZM78.974 83.734v-9.737a.366.366 0 0 0-.366-.366h-3.7a.366.366 0 0 0-.365.366v4.722a.366.366 0 0 1-.365.365H70.43a.366.366 0 0 0-.366.366v4.284c0 .201.164.365.366.365h8.178a.366.366 0 0 0 .366-.365Z"
    />
  </svg>
);

export default React.memo(FirebaseAnalyticsIcon);
