import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const FirebaseAuthIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M58.981 31.547 51.875 17.95A2.125 2.125 0 0 0 50 16.816c-.81 0-1.513.453-1.869 1.122l-.006.012L16.922 73.87l42.06-42.322Zm24.931 47.71-8.787-54.672a2.114 2.114 0 0 0-2.084-1.785c-.591 0-1.122.24-1.507.631L16.084 79.26l30.679 17.287c.893.51 1.962.813 3.1.813 1.137 0 2.209-.3 3.13-.829l-.03.016 30.95-17.29ZM18.328 64.84l9.235-59.4a2.122 2.122 0 0 1 2.093-1.803c.806 0 1.51.45 1.866 1.115l.006.013 9.931 18.631-23.13 41.444Z"
    />
    <path
      fill="#7A7A7B"
      d="M78.463 92.772c9.162 0 16.59-7.447 16.59-16.634 0-9.186-7.428-16.633-16.59-16.633-9.162 0-16.59 7.447-16.59 16.633 0 9.187 7.428 16.634 16.59 16.634Z"
    />
    <path
      fill="#fff"
      d="M77.748 72.68a2.645 2.645 0 1 1-5.291 0 2.645 2.645 0 0 1 5.29 0ZM84.533 72.68a2.646 2.646 0 1 1-5.291 0 2.646 2.646 0 0 1 5.29 0ZM81.05 79.377v2.532c0 .03-.025.055-.056.055H69.096a.056.056 0 0 1-.055-.055v-2.53c0-.012.003-.023.01-.033.643-.84 2.743-2.491 6.108-2.491 3.367 0 5.317 1.653 5.882 2.492.006.01.009.02.009.03ZM82.873 79.36c0-.619-.865-1.78-1.442-2.27-.041-.035-.015-.107.04-.106 3.854.037 5.225 1.286 6.396 2.36.011.01.018.026.018.041v2.524c0 .03-.025.055-.056.055h-4.905a.055.055 0 0 1-.055-.05c-.027-.467.004-1.542.004-2.553Z"
    />
  </svg>
);

export default React.memo(FirebaseAuthIcon);
