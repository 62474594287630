import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const JavaScriptIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M15.25 6.75a9 9 0 0 0-9 9v69.5a9 9 0 0 0 9 9h69.5a9 9 0 0 0 9-9v-69.5a9 9 0 0 0-9-9h-69.5Zm37.875 67.969c0 8.517-5.031 12.67-12.315 12.67-6.578 0-10.396-3.407-12.333-7.518l6.695-4.053c1.29 2.291 2.271 4.229 5.09 4.229 2.343 0 4.27-1.057 4.27-5.168V47.375h8.593v27.344Zm19.404 12.474c-7.635 0-12.568-3.445-14.976-8.203l6.705-3.88c1.758 2.878 4.053 4.796 8.105 4.796 3.406 0 5.385-1.508 5.385-3.857 0-2.819-2.037-3.817-5.797-5.469l-2.054-.883c-5.934-2.523-9.868-5.695-9.868-12.392 0-6.166 4.698-10.67 12.04-10.67 5.228 0 8.984 1.625 11.689 6.383l-6.414 4.123c-1.41-2.526-2.93-3.516-5.285-3.516-2.409 0-3.936 1.527-3.936 3.516 0 2.466 1.527 3.465 5.05 4.992l2.055.88c6.99 2.997 10.926 6.055 10.926 12.923.002 7.402-5.812 11.257-13.625 11.257Z"
    />
  </svg>
);

export default React.memo(JavaScriptIcon);
