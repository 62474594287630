import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const AxiosIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#E1E1E1"
      d="M86.562 35H75.273l-6.64 11.023L62.193 35H50.785l12.417 18.195-8.034 11.753-13.214-29.882h-9.961l16.468 37.053H60.88l7.768-11.753 7.704 11.753H86.51L74.227 53.194 86.561 35Z"
    />
    <path fill="#7A7A7B" d="M23.156 93.82H0L38.234 7.1h23.328L23.157 93.82Z" />
    <path
      fill="#1B1B1F"
      d="m76.777 93.82 23.223.083L61.562 7.097H38.234l38.544 86.722"
    />
  </svg>
);

export default React.memo(AxiosIcon);
