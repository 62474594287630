import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const DirectusIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        d="M79.946 58.454a7.25 7.25 0 0 1-1.192-.383 2.739 2.739 0 0 1-.846-.58c.234-2.033 0-3.8.196-5.8.767-7.758 5.646-5.3 10.025-6.57 2.73-.767 5.459-2.342 6.146-5.567a56.37 56.37 0 0 0-9.988-9.183c-11.875-8.45-27.391-11.833-41.491-9.488a21.305 21.305 0 0 0 9.325 8.642s-3.821 0-7.096-2.446c-.958.384-2.883 1.142-3.804 1.6a21.226 21.226 0 0 0 27.625 1.542c-.042.07-.771 1.187-1.654 5.833-1.959 9.917-7.609 9.146-14.6 6.65-14.521-5.266-22.513-.387-29.771-10.375a6.7 6.7 0 0 0-3.417 5.842c0 2.496 1.38 4.608 3.375 5.762 1.092-1.45 1.584-1.858 3.483-1.858-2.941 1.667-3.291 3.125-4.558 7.158-1.533 4.88-.883 9.875-8.067 11.18-3.804.191-3.725 2.766-5.108 6.608C6.8 72.017 4.496 74.013 0 78.738c1.846 2.229 3.767 2.5 5.725 1.691 4.033-1.691 7.146-6.916 10.063-10.296 3.266-3.766 11.104-2.154 17.02-5.841 4.071-2.496 6.071-5.875 3.38-11.6a11.333 11.333 0 0 1 2.92 6.916c6.838-.887 15.984 7.45 24.317 8.834a14.89 14.89 0 0 1-2.033-3.417c-.959-2.308-1.267-4.417-1.075-6.262.766 4.57 5.375 10.445 12.791 10.987 1.884.15 3.959-.08 6.109-.733 2.575-.767 4.958-1.767 7.8-1.23 2.112.388 4.07 1.459 5.3 3.267 1.846 2.688 5.875 3.267 7.683-.037-4.07-10.642-15.292-11.334-20.054-12.563Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(DirectusIcon);
