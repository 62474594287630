import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const WebViewIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        d="M97.217 66.949a49.785 49.785 0 0 0 2.782-16.45c0-5.76-.982-11.295-2.782-16.448a3.622 3.622 0 0 0-.16-.452C90.114 14.322 71.642.5 50 .5 28.36.5 9.887 14.322 2.943 33.6a3.557 3.557 0 0 0-.16.45A49.784 49.784 0 0 0 .002 50.5c0 5.76.982 11.295 2.783 16.449.044.155.096.306.159.451C9.888 86.678 28.359 100.5 50 100.5c21.641 0 40.113-13.822 47.058-33.1.063-.145.115-.296.159-.451ZM50 93.333c-1.904 0-4.974-3.447-7.54-11.145-1.238-3.715-2.23-7.986-2.952-12.632h20.985c-.723 4.646-1.714 8.916-2.952 12.632-2.567 7.698-5.637 11.145-7.54 11.145ZM38.638 62.389a132.79 132.79 0 0 1-.526-11.89c0-4.08.181-8.066.526-11.888h22.724c.344 3.822.527 7.809.527 11.889 0 4.08-.183 8.067-.527 11.889H38.638ZM7.168 50.5c0-4.123.587-8.112 1.68-11.889h22.601c-.335 3.9-.505 7.898-.505 11.89 0 3.99.17 7.988.505 11.888H8.848A42.71 42.71 0 0 1 7.168 50.5ZM50 7.667c1.904 0 4.975 3.447 7.54 11.145 1.239 3.715 2.23 7.986 2.953 12.632H39.507c.723-4.646 1.714-8.917 2.953-12.632C45.026 11.113 48.096 7.667 50 7.667Zm18.55 30.944h22.602a42.709 42.709 0 0 1 1.68 11.89c0 4.123-.587 8.112-1.68 11.888H68.551c.335-3.9.505-7.898.505-11.889 0-3.99-.17-7.988-.505-11.889Zm19.802-7.167H67.729c-1.275-8.79-3.462-16.677-6.53-22.288 11.895 3.225 21.75 11.459 27.153 22.288ZM38.802 9.156c-3.069 5.61-5.256 13.497-6.53 22.288H11.649C17.05 20.614 26.905 12.381 38.8 9.156Zm-27.154 60.4h20.624c1.274 8.79 3.461 16.677 6.53 22.288-11.896-3.225-21.75-11.458-27.154-22.288Zm49.55 22.288c3.069-5.61 5.256-13.497 6.53-22.288h20.624c-5.403 10.83-15.258 19.063-27.153 22.288Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(WebViewIcon);
