import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ExpoIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M100 77.984c-3.638.925-5.888.04-7.906-2.99L77.738 55.13l-2.076-2.75L58.897 75.06c-1.916 2.728-3.925 3.916-7.5 2.95L72.866 49.19 52.878 23.16c3.438-.67 5.813-.329 7.922 2.75l14.89 20.109 15-20c1.922-2.728 3.988-3.766 7.438-2.76l-7.75 10.275-10.5 13.672c-1.25 1.563-1.078 2.631.072 4.14L100 77.985ZM.025 48.71l1.756-8.637C6.563 22.978 26.156 15.875 39.67 26.437c7.897 6.213 9.86 15 9.469 24.844H4.625C3.956 69 16.709 79.694 32.969 74.237c5.703-1.915 9.062-6.38 10.743-11.968.854-2.8 2.266-3.238 4.897-2.438-1.343 6.988-4.375 12.825-10.78 16.478-9.573 5.469-23.235 3.7-30.423-3.9C3.125 68 1.356 62.412.563 56.437.438 55.45.188 54.51 0 53.562c.017-1.616.025-3.233.025-4.85v-.003Zm4.681-1.187h40.225c-.262-12.813-8.24-21.913-19.143-21.99-11.97-.095-20.563 8.79-21.082 21.99Z"
    />
  </svg>
);

export default React.memo(ExpoIcon);
