import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const SentryIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#000"
        d="M98.596 85.12c1.871-3.116 1.871-6.233 0-9.35l-40.5-70.403C56.233 2.254 53.116 1 50 1c-3.117 0-6.23 1.87-8.096 4.367l-13.087 23.05 3.116 1.87C41.904 35.897 50 43.992 55.608 53.963c4.988 8.725 8.1 18.067 8.725 28.042h-9.346c-.62-8.104-3.116-16.204-7.475-23.683C43.15 49.6 36.296 42.746 27.575 37.758l-3.12-1.862-12.46 21.183 3.113 1.871c8.1 4.98 13.709 13.08 14.954 22.43H9.5c-.62 0-1.25-.626-1.25-.626s-.617-.625 0-1.246l5.617-9.97c-1.871-1.863-4.367-3.117-6.859-3.734L1.4 75.775c-1.87 3.117-1.87 6.233 0 9.35 1.867 3.108 4.358 4.354 8.096 4.354h28.662v-3.733c0-6.854-1.87-13.088-4.983-19.321-2.496-4.98-6.233-8.72-10.592-11.833l4.363-8.105c5.612 4.359 10.596 9.346 14.329 15.58 4.362 7.47 6.233 15.575 6.233 23.675v3.733h24.296v-3.733c0-12.459-3.112-24.925-9.967-36.138C56.85 39.63 48.75 31.534 39.4 25.3l9.35-16.196C49.38 8.48 50 8.48 50 8.48c.625 0 .625 0 1.246.625l40.504 70.4c.617.617 0 1.246 0 1.246s-.625.625-1.25.625h-9.346v7.48H90.5c3.733.62 6.23-.626 8.096-3.734Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(SentryIcon);
