import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const RxAndroidBle2Icon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      d="M79.833 26.334 55.167 50.5l24.166 24.667-24.166 24.166v-48.75l-.041-.04-26.543 27.29L1.25 50.5l26.917-26.916 26.959 26.958.04-.042V1.834l24.667 24.5Z"
    />
    <circle cx={86.333} cy={50.833} r={12.667} fill="#1B1B1F" />
  </svg>
);

export default React.memo(RxAndroidBle2Icon);
