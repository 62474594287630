import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const FirebaseIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M58.981 31.547 51.875 17.95A2.125 2.125 0 0 0 50 16.816c-.81 0-1.513.453-1.869 1.122l-.006.012L16.922 73.87l42.06-42.322Zm24.931 47.71-8.787-54.672a2.114 2.114 0 0 0-2.084-1.785c-.591 0-1.122.24-1.507.631L16.084 79.26l30.679 17.287c.893.51 1.962.813 3.1.813 1.137 0 2.209-.3 3.13-.829l-.03.016 30.95-17.29ZM18.328 64.84l9.235-59.4a2.122 2.122 0 0 1 2.093-1.803c.806 0 1.51.45 1.866 1.115l.006.013 9.931 18.631-23.13 41.444Z"
    />
  </svg>
);

export default React.memo(FirebaseIcon);
