import * as React from 'react';
import Text from '../Text';
import './style.scss';

export interface StageItemType {
  text: string;
  color: 'yellow' | 'green' | 'blue' | 'red' | 'orange';
}

export const StageItem: { [key: string]: StageItemType } = {
  Scoping: {
    text: 'Scoping',
    color: 'yellow',
  },
  Planing: {
    text: 'Planing',
    color: 'green',
  },
  UIDesign: {
    text: 'UI Design',
    color: 'orange',
  },
  CustomerInt: {
    text: 'Customer Interviews',
    color: 'orange',
  },
  Backlog: {
    text: 'Backlog Setup',
    color: 'blue',
  },
  Discovery: {
    text: 'Discovery',
    color: 'blue',
  },
  ValidatingProtocol: {
    text: 'Validating protocol',
    color: 'blue',
  },
  UXPrototyping: {
    text: 'UX-Prototyping',
    color: 'red',
  },
  UXWireframing: {
    text: 'UX-Wireframing',
    color: 'red',
  },
  Design: {
    text: 'Design',
    color: 'yellow',
  },
  UXDesign: {
    text: 'UX Design',
    color: 'yellow',
  },
  Development: {
    text: 'App Development',
    color: 'green',
  },
  FrontEnd: {
    text: 'FrontEnd Dev',
    color: 'red',
  },
  BackEnd: {
    text: 'BackEnd Dev',
    color: 'blue',
  },
  APIBack: {
    text: 'API & BackEnd',
    color: 'blue',
  },
  QATesting: {
    text: 'QA & Testing',
    color: 'orange',
  },
  Deployment: {
    text: 'Deployment',
    color: 'green',
  },
  Release: {
    text: 'Release',
    color: 'red',
  },
  Handover: {
    text: 'Handover',
    color: 'yellow',
  },
  CustomerTest: {
    text: 'Testing with 1st customers',
    color: 'yellow',
  },
};

export interface StageType {
  title: string;
  duration?: string;
  items: StageItemType[];
}

export interface Props {
  stages: StageType[];
  lightMode?: boolean;
}

const Stages: React.FC<Props> = ({ stages }) => {
  return (
    <div className="stages">
      {stages?.map((item, index) => {
        const stageTitle = item.duration
          ? `${item.title} - ${item.duration}`
          : item.title;
        return (
          <div
            key={`stages-stage-${item.title}-${index}`}
            className="stages__container"
          >
            <Text
              type="label"
              weight="bold"
              className="stages__container__title"
            >
              {stageTitle}
            </Text>
            <div className={'stages__container__tags'}>
              {item.items?.map((tag, index) => {
                return (
                  <Text
                    key={`stages-stage-item-${tag.text}-${index}`}
                    color="dark"
                    type="label"
                    className="stages__container__tags__tag"
                  >
                    {tag.text}
                  </Text>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stages;
