import {
  FC,
  FunctionComponent,
  MemoExoticComponent,
  SVGProps,
  memo,
} from 'react';
import React from 'react';

import ReactNativeIcon from './TechIcons/ReactNativeIcon';
import ReactNativeWebIcon from './TechIcons/ReactNativeWebIcon';
import TypeScriptIcon from './TechIcons/TypeScriptIcon';
import JavaScriptIcon from './TechIcons/JavaScriptIcon';
import ESLintIcon from './TechIcons/ESLintIcon';
import PrettierIcon from './TechIcons/PrettierIcon';
import ExpoIcon from './TechIcons/ExpoIcon';
import ReactNativePaperIcon from './TechIcons/ReactNativePaperIcon';
import GitHubIcon from './TechIcons/GitHubIcon';
import HasuraIcon from './TechIcons/HasuraIcon';
import ExpressIcon from './TechIcons/ExpressIcon';
import ApolloClientIcon from './TechIcons/ApolloClientIcon';
import AxiosIcon from './TechIcons/AxiosIcon';
import PostgressIcon from './TechIcons/PostgressIcon';
import DigitalOceanIcon from './TechIcons/DigitalOceanIcon';
import I18NextIcon from './TechIcons/I18NextIcon';
import NestJSIcon from './TechIcons/NestJSIcon';
import GRPCIcon from './TechIcons/GRPCIcon';
import PrismaIcon from './TechIcons/PrismaIcon';
import GitLabIcon from './TechIcons/GitLabIcon';
import FastlaneIcon from './TechIcons/FastlaneIcon';
import KeycloakIcon from './TechIcons/KeycloakIcon';
import DirectusIcon from './TechIcons/DirectusIcon';
import ReactNavigationIcon from './TechIcons/ReactNavigationIcon';
import SentryIcon from './TechIcons/SentryIcon';
import FirebaseAuthIcon from './TechIcons/FirebaseAuthIcon';
import FirebaseAnalyticsIcon from './TechIcons/FirebaseAnalyticsIcon';
import FirebaseFunctionsIcon from './TechIcons/FirebaseFunctionsIcon';
import HygraphIcon from './TechIcons/HygraphIcon';
import AWSIcon from './TechIcons/AWSIcon';
import RxAndroidBle2Icon from './TechIcons/RxAndroidBle2Icon';
import ZodisIcon from './TechIcons/ZodisIcon';
import ReactReanimatedIcon from './TechIcons/ReactReanimatedIcon';
import WebViewIcon from './TechIcons/WebViewIcon';
import GraphQLIcon from './TechIcons/GraphQLIcon';
import CoconutIcon from './TechIcons/CoconutIcon';
import KeystoneJSIcon from './TechIcons/KeystoneJSIcon';
import FirebaseIcon from './TechIcons/FirebaseIcon';
import ReduxIcon from './TechIcons/ReduxIcon';
import GoogleMapsIcon from './TechIcons/GoogleMapsIcon';
import NodeJsIcon from './TechIcons/NodeJsIcon';

export enum TechVariants {
  ReactNative = 'React Native',
  ReactNativeWeb = 'React Native Web',
  GraphQL = 'GraphQL',
  TypeScript = 'Typescript',
  JavaScript = 'Javascript',
  ESLint = 'ESLint',
  Prettier = 'Prettier',
  Expo = 'Expo',
  ReactNativePaper = 'React Native Paper',
  GitHub = 'GitHub',
  Hasura = 'Hasura',
  Express = 'Express',
  ApolloClient = 'ApolloClient',
  Axios = 'Axios',
  Postgres = 'Postgres',
  DigitalOcean = 'DigitalOcean',
  I18Next = 'i18Next',
  NestJS = 'Nest.js',
  GRPC = 'gRPC',
  Prisma = 'Prisma',
  GitLab = 'GitLab CI/CD',
  Fastlane = 'Fastlane',
  Keycloak = 'Keycloak',
  Directus = 'Directus',
  ReactNavigation = 'React Navigation',
  Sentry = 'Sentry',
  FirebaseAuth = 'Firebase Auth',
  FirebaseAnalytics = 'Firebase Analytics',
  FirebaseFunctions = 'Firebase Functions',
  Hygraph = 'Hygraph',
  AWS = 'AWS',
  RxAndroidBle2 = 'RxAndroidBle 2',
  Zodios = 'Zodios',
  ReactReanimated = 'React Reanimated',
  WebView = 'WebView',
  KeystoneJS = 'KeystoneJS',
  Coconut = 'Coconut',
  Firebase = 'Firebase',
  Redux = 'Redux',
  ReactReactNative = 'React / React Native',
  NodeJs = 'NodeJs',
  GoogleMaps = 'Google Maps',
}

export interface TechIconItemProps extends SVGProps<SVGSVGElement> {}

type Icon = MemoExoticComponent<FunctionComponent<TechIconItemProps>>;

const TechIcons: Record<TechVariants, Icon> = {
  [TechVariants.ReactNative]: ReactNativeIcon,
  [TechVariants.ReactNativeWeb]: ReactNativeWebIcon,
  [TechVariants.GraphQL]: GraphQLIcon,
  [TechVariants.TypeScript]: TypeScriptIcon,
  [TechVariants.JavaScript]: JavaScriptIcon,
  [TechVariants.ESLint]: ESLintIcon,
  [TechVariants.Prettier]: PrettierIcon,
  [TechVariants.Expo]: ExpoIcon,
  [TechVariants.ReactNativePaper]: ReactNativePaperIcon,
  [TechVariants.GitHub]: GitHubIcon,
  [TechVariants.Hasura]: HasuraIcon,
  [TechVariants.Express]: ExpressIcon,
  [TechVariants.ApolloClient]: ApolloClientIcon,
  [TechVariants.Axios]: AxiosIcon,
  [TechVariants.Postgres]: PostgressIcon,
  [TechVariants.DigitalOcean]: DigitalOceanIcon,
  [TechVariants.I18Next]: I18NextIcon,
  [TechVariants.NestJS]: NestJSIcon,
  [TechVariants.GRPC]: GRPCIcon,
  [TechVariants.Prisma]: PrismaIcon,
  [TechVariants.GitLab]: GitLabIcon,
  [TechVariants.Fastlane]: FastlaneIcon,
  [TechVariants.Keycloak]: KeycloakIcon,
  [TechVariants.Directus]: DirectusIcon,
  [TechVariants.ReactNavigation]: ReactNavigationIcon,
  [TechVariants.Sentry]: SentryIcon,
  [TechVariants.FirebaseAuth]: FirebaseAuthIcon,
  [TechVariants.FirebaseAnalytics]: FirebaseAnalyticsIcon,
  [TechVariants.FirebaseFunctions]: FirebaseFunctionsIcon,
  [TechVariants.Hygraph]: HygraphIcon,
  [TechVariants.AWS]: AWSIcon,
  [TechVariants.RxAndroidBle2]: RxAndroidBle2Icon,
  [TechVariants.Zodios]: ZodisIcon,
  [TechVariants.ReactReanimated]: ReactReanimatedIcon,
  [TechVariants.WebView]: WebViewIcon,
  [TechVariants.KeystoneJS]: KeystoneJSIcon,
  [TechVariants.Coconut]: CoconutIcon,
  [TechVariants.Firebase]: FirebaseIcon,
  [TechVariants.Redux]: ReduxIcon,
  [TechVariants.ReactReactNative]: ReactNativeIcon,
  [TechVariants.GoogleMaps]: GoogleMapsIcon,
  [TechVariants.NodeJs]: NodeJsIcon,
};

interface Props {
  variant: TechVariants;
  size: 'large' | 'small';
}

const TechIcon: FC<Props> = ({ variant, size }) => {
  const Icon = TechIcons[variant];

  const width = size === 'large' ? 100 : 40;

  return <Icon width={width} height={width} />;
};

export default memo(TechIcon);
