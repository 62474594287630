import React, { memo } from 'react';
import { TechIconItemProps } from '../TechIcon';

const CoconutIcon = (props: TechIconItemProps) => (
  <svg
    viewBox="0 0 100 101"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M65.3171 87.0659C74.632 80.6515 77.6315 57.6437 66.715 41.7916C55.583 25.6267 25.3904 24.6906 15.5592 34.9587C7.32783 43.556 1.4549 81.4367 8.4876 91.0706C15.2257 100.301 54.4663 94.5379 65.3171 87.0659Z"
      fill="url(#paint0_linear_1625_1308)"
    />
    <path
      d="M94.6464 37.1916C94.6464 54.3252 80.7569 68.2148 63.6233 68.2148C46.4896 68.2148 32.6001 54.3252 32.6001 37.1916C32.6001 20.058 46.4896 6.16846 63.6233 6.16846C80.7569 6.16846 94.6464 20.058 94.6464 37.1916Z"
      fill="white"
      fillOpacity="0.9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.6233 67.3098C80.2571 67.3098 93.7414 53.8254 93.7414 37.1916C93.7414 20.5578 80.2571 7.07342 63.6233 7.07342C46.9894 7.07342 33.5051 20.5578 33.5051 37.1916C33.5051 53.8254 46.9894 67.3098 63.6233 67.3098ZM63.6233 68.2148C80.7569 68.2148 94.6464 54.3252 94.6464 37.1916C94.6464 20.058 80.7569 6.16846 63.6233 6.16846C46.4896 6.16846 32.6001 20.058 32.6001 37.1916C32.6001 54.3252 46.4896 68.2148 63.6233 68.2148Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1625_1308"
        x1="64.0072"
        y1="38.3655"
        x2="8.45011"
        y2="91.5175"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.366058" stopColor="#828181" />
        <stop offset="1" stopColor="#303030" />
      </linearGradient>
    </defs>
  </svg>
);
export default memo(CoconutIcon);
