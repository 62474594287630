import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import CardComment from './CardUpdated';
import useSlider from '../../hooks/useSlider';
import { graphql, useStaticQuery } from 'gatsby';
import { ClientFeedbackItem } from '../../utils/generalTypes';
import SliderHeader from '../SliderHeader';
import SliderControlButton from '../SliderControlButton';

interface Props {
  title?: string;
  commentsOrder?: string[];
}

const Comments: React.SFC<Props> = ({ title, commentsOrder }) => {
  const { onSlickNext, onSlickPrevious, sliderRef } = useSlider();

  const sliderSettings = React.useMemo<Settings>(
    () => ({
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 12000,
      className: 'built-by-stormotion__content__slider',
      infinite: true,
      pauseOnHover: true,
      speed: 750,
      slidesToScroll: 1,
      slidesToShow: 1,
      dotsClass: 'slick-dots tabs',
      lazyLoad: 'ondemand',
    }),
    []
  );

  const {
    allSanityClientFeedbackItem: { nodes: feedbacks },
  } = useStaticQuery<{
    allSanityClientFeedbackItem: { nodes: ClientFeedbackItem[] };
  }>(graphql`
    {
      allSanityClientFeedbackItem {
        nodes {
          image {
            asset {
              gatsbyImageData(
                height: 350
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          authorImage {
            asset {
              gatsbyImageData(
                height: 72
                width: 72
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          authorName
          body
          authorPosition
          videoLink
          caseStudyLink
          id
        }
      }
    }
  `);

  const filteredFeedbacks = React.useMemo(() => {
    if (commentsOrder) {
      return feedbacks
        .filter((item) => commentsOrder.includes(item.authorName))
        .sort(
          (a, b) =>
            commentsOrder.indexOf(a.authorName) -
            commentsOrder.indexOf(b.authorName)
        );
    }

    return feedbacks;
  }, [commentsOrder, feedbacks]);

  return (
    <section className={'comments'}>
      {title ? (
        <div>
          <SliderHeader
            title={'testimonials'}
            subtitle={'shuffle through the slider. drag or swipe.'}
            className="comments__container"
          />
        </div>
      ) : null}
      <Slider {...sliderSettings} ref={sliderRef}>
        {filteredFeedbacks?.map((feedback, index) => (
          <CardComment
            key={`card-comment-${feedback.authorName}`}
            {...feedback}
          />
        ))}
      </Slider>
      <div className="comments__buttons comments__container">
        <SliderControlButton direction="prev" onClick={onSlickPrevious} />
        <SliderControlButton direction="next" onClick={onSlickNext} />
      </div>
    </section>
  );
};

export default Comments;
