import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import Text from '../Text';
import './style.scss';
import ChevronRight from '../icons/ChevronRight';
import Link from '../Link';

type BreadcrumbsProps = {
  currentPageTitle?: string;
  className?: string;
  textColor?: 'dark' | 'light';
  path?: string;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  className,
  currentPageTitle,
  textColor = 'dark',
  path: propsPath,
}) => {
  const { t } = useTranslation();

  const mergedStyle = classNames('breadcrumbs', className);
  const path =
    typeof window !== 'undefined'
      ? propsPath ?? window?.location?.pathname
      : '';

  const pathArray = useMemo(() => {
    const splitPath = path?.split('/');

    if (splitPath?.[splitPath?.length - 1] === '') {
      return splitPath.slice(0, -1);
    }

    return splitPath;
  }, [path]);

  const Content = useMemo(
    () =>
      pathArray?.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === pathArray.length - 1;

        const title = isFirst
          ? t('home')
          : isLast
            ? currentPageTitle ?? item
            : item;

        return (
          <div
            key={index}
            className="breadcrumbs__breadcrumb"
            itemScope
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            {isLast ? (
              <div itemProp="item">
                <Text
                  color={textColor}
                  weight="bold"
                  type="p"
                  className={classNames('breadcrumbs__breadcrumb-text', {
                    'breadcrumbs__breadcrumb-text--last_dark':
                      isLast && textColor === 'dark',
                    'breadcrumbs__breadcrumb-text--last_light':
                      isLast && textColor === 'light',
                  })}
                  itemProp="name"
                >
                  {title}
                </Text>
                <meta itemProp="position" content={String(index + 1)} />
              </div>
            ) : (
              <>
                <Link
                  className="breadcrumbs__breadcrumb-link"
                  href={`/${item}`}
                  itemProp="item"
                >
                  <Text
                    color={textColor}
                    weight="bold"
                    type="p"
                    className={classNames('breadcrumbs__breadcrumb-text', {
                      'breadcrumbs__breadcrumb-text--last': isLast,
                    })}
                    itemProp="name"
                  >
                    {title}
                  </Text>
                  <meta itemProp="position" content={String(index + 1)} />
                </Link>
                <ChevronRight
                  pathClassName={classNames({
                    'breadcrumbs__breadcrumb-chevron_dark':
                      textColor === 'dark',
                    'breadcrumbs__breadcrumb-chevron_light':
                      textColor === 'light',
                  })}
                />
              </>
            )}
          </div>
        );
      }),
    [currentPageTitle, pathArray, t, textColor]
  );

  return (
    <div
      className={mergedStyle}
      itemScope
      itemType="http://schema.org/BreadcrumbList"
    >
      {Content}
    </div>
  );
};

export default React.memo(Breadcrumbs);
