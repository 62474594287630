import React from 'react';
import ArrowLongIcon from '../icons/ArrowLongIcon';
import './style.scss';
import { FC, memo } from 'react';
import classNames from 'classnames';
import { IconColor } from '../../utils/colorTypes';

interface Props {
  onClick: () => void;
  direction: 'prev' | 'next';
  color?: IconColor;
}

const SliderControlButton: FC<Props> = ({
  onClick,
  direction,
  color = 'light',
}) => (
  <button
    onClick={onClick}
    className={classNames('slider-controls__button', {
      'slider-controls__button__prev': direction === 'prev',
      'slider-controls__button__light': color === 'light',
      'slider-controls__button__dark': color === 'dark',
    })}
  >
    <ArrowLongIcon color={color === 'light' ? 'dark' : 'light'} />
  </button>
);

export default memo(SliderControlButton);
