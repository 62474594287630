import React, { FC, memo } from 'react';
import { IconColor } from '../../utils/colorTypes';
import colors from '../../theme/colors';

interface Props {
  color: IconColor;
}

const DoubleArrowIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="34" height="8" viewBox="0 0 34 8" fill="none">
      <path
        d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659729 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM33.3536 4.35356C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464469C29.9763 0.269206 29.6597 0.269206 29.4645 0.464469C29.2692 0.659731 29.2692 0.976313 29.4645 1.17158L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53554C29.6597 7.7308 29.9763 7.7308 30.1716 7.53554L33.3536 4.35356ZM1 4.5L33 4.5L33 3.5L1 3.5L1 4.5Z"
        fill={colors.icon[color]}
      />
    </svg>
  );
};

export default memo(DoubleArrowIcon);
