import { StageItem, StageType } from '../components/Stages';
import { AppStage } from './appTypes';

export const convertAPIStagesToLayoutStages = (
  stages: AppStage[]
): StageType[] => {
  return stages.map((stageItem) => ({
    title: stageItem.title,
    duration: stageItem.duration,
    items: stageItem.items.map((item) => StageItem[item]),
  }));
};
