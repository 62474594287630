import React, { memo } from 'react';
import { AppStoreLinks } from '../../utils/appTypes';
import GooglePlayIcon from '../icons/GooglePlay';
import './style.scss';
import AppStoreIcon from '../icons/AppStore';

interface Props {
  links: AppStoreLinks;
}

const ProductStoreLinks: React.FC<Props> = ({
  links: { playStore, appStore },
}) => {
  return (
    <div className="links">
      {playStore && (
        <a href={playStore} target="_blank" rel="noreferrer">
          <GooglePlayIcon />
        </a>
      )}
      {appStore && (
        <a href={appStore} target="_blank" rel="noreferrer">
          <AppStoreIcon />
        </a>
      )}
    </div>
  );
};

export default memo(ProductStoreLinks);
