import * as React from 'react';
import { TechIconItemProps } from '../TechIcon';

const ApolloClientIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M48.798 1.543c-.187.008-.892.068-1.572.119-8.496.628-17.11 3.772-24.17 8.818-2.337 1.665-4.18 3.229-6.236 5.284C5.002 27.591.134 44.514 3.83 60.954c1.512 6.745 4.74 13.533 9.022 18.988 6.72 8.572 15.93 14.612 26.26 17.229 4.206 1.07 7.698 1.504 12.107 1.504 4.35 0 7.553-.391 11.699-1.402 5.955-1.462 11.188-3.866 16.422-7.527 5.012-3.518 9.677-8.445 13.024-13.755 4.316-6.865 6.771-14.443 7.374-22.76.128-1.75.051-6.083-.136-7.867-.374-3.543-1.325-8.216-1.877-9.193-.221-.382-.841-.875-1.3-1.036-.162-.051-.518-.094-.807-.094-1.275 0-2.345 1.062-2.345 2.311 0 .298.136 1.011.331 1.784 1.682 6.542 1.818 13.73.383 20.288-1.835 8.402-5.93 15.81-12.107 21.885-2.88 2.83-5.658 4.92-9.252 6.975-8.385 4.8-18.81 6.618-28.588 4.987-7.425-1.232-13.874-4.086-19.999-8.852-2.88-2.243-6.389-5.939-8.564-9.031-3.755-5.336-6.117-10.96-7.34-17.493-.62-3.33-.833-7.748-.544-11.342.442-5.454 1.81-10.518 4.13-15.318 1.07-2.234 1.732-3.398 3.015-5.327 2.617-3.959 6.083-7.663 9.915-10.602 4.494-3.458 9.83-6.083 15.224-7.494 2.914-.764 5.242-1.146 8.198-1.359 10.697-.764 21.096 2.311 29.566 8.76.628.475 1.291.993 1.47 1.155l.322.28-.127.357c-.654 1.835-.17 4.12 1.206 5.718.706.815 1.98 1.58 3.059 1.835.739.178 2.022.178 2.77 0 1.894-.45 3.661-2.073 4.222-3.875.629-2.013.263-4.001-1.028-5.615-1.427-1.784-3.883-2.583-6.151-1.988l-.493.127-.697-.578c-2.829-2.37-6.252-4.562-9.855-6.303-5.845-2.83-11.715-4.35-18.401-4.758-1.283-.076-4.316-.093-5.14-.025Z"
    />
    <path
      fill="#000"
      d="M43.768 29.494c-.9 2.328-2.854 7.425-4.358 11.325-1.504 3.9-4.588 11.91-6.856 17.798-2.26 5.888-4.137 10.73-4.154 10.765-.026.042 2.361.067 5.293.067h5.343l.315-.875c.178-.475.807-2.157 1.393-3.738l1.07-2.863h8.02c6.39 0 8.02-.025 7.995-.11-.085-.23-1.393-3.968-2.09-5.964l-.748-2.124-5.31-.043-5.3-.042 3.448-9.507c2.073-5.71 3.475-9.447 3.5-9.362.035.076 1.411 3.993 3.068 8.7 1.656 4.706 4.392 12.454 6.074 17.22l3.05 8.666 5.318.026c2.923.008 5.319 0 5.319-.018 0-.017-.688-1.826-1.538-4.01a5979.88 5979.88 0 0 1-4.044-10.483 4513.56 4513.56 0 0 0-3.44-8.92c-.51-1.335-1.402-3.646-1.98-5.14-.578-1.496-2.082-5.413-3.356-8.709a2494.017 2494.017 0 0 1-2.48-6.44l-.162-.442H45.39l-1.623 4.223Z"
    />
  </svg>
);

export default React.memo(ApolloClientIcon);
