import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const GitLabIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      d="m96.428 41.106-.135-.345-13.07-34.11a3.405 3.405 0 0 0-1.345-1.62 3.5 3.5 0 0 0-4 .215 3.5 3.5 0 0 0-1.16 1.76l-8.825 27H32.158l-8.825-27a3.43 3.43 0 0 0-1.16-1.765 3.5 3.5 0 0 0-4-.215 3.435 3.435 0 0 0-1.345 1.62L3.733 40.741l-.13.345a24.27 24.27 0 0 0 8.05 28.05l.045.035.12.085 19.91 14.91 9.85 7.455 6 4.53a4.035 4.035 0 0 0 4.88 0l6-4.53 9.85-7.455 20.03-15 .05-.04a24.28 24.28 0 0 0 8.04-28.02Z"
    />
  </svg>
);

export default React.memo(GitLabIcon);
