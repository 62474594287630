import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const GoogleMapsIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#5F5F5F"
      d="M34.412 74.08c3.072 3.859 6.201 8.71 7.839 11.642 1.994 3.81 2.82 6.382 4.304 10.99.867 2.523 1.694 3.288 3.429 3.288 1.897 0 2.764-1.286 3.429-3.289 1.386-4.322 2.456-7.611 4.15-10.745C64.096 73.592 74.748 64.8 80.746 52.752c0 0 3.948-7.36 3.948-17.666 0-9.605-3.891-16.28-3.891-16.28L34.436 74.161l-.024-.082Z"
    />
    <path
      fill="#818181"
      d="M18.686 51.449c3.737 8.547 10.863 16.037 15.726 22.63L60.19 43.39s-3.64 4.787-10.213 4.787c-7.328 0-13.294-5.861-13.294-13.27 0-5.095 3.023-8.628 3.023-8.628-18.968 2.833-17.914 7.449-21.075 25.154l.056.017Z"
    />
    <path
      fill="#4F4F4F"
      d="M60.514 1.604C69.106 4.379 76.4 10.233 80.779 18.78l-20.59 24.667s3.024-3.55 3.024-8.63c0-7.562-6.355-13.269-13.213-13.269-6.51 0-10.214 4.73-10.214 4.73 1.581-3.615 17.915-23.445 20.752-24.666l-.024-.008Z"
    />
    <path
      fill="#000"
      d="M23.388 12.455C28.51 6.333 37.492 0 49.895 0c5.99 0 10.538 1.596 10.538 1.596l-20.67 24.666c-1.395-.76-14.997-11.397-16.375-13.84v.033Z"
    />
    <path
      fill="#4F4F4F"
      d="M18.686 51.449s-3.38-6.74-3.38-16.444c0-9.2 3.583-17.258 8.106-22.468l16.374 13.839-21.075 25.073h-.025Z"
    />
  </svg>
);

export default React.memo(GoogleMapsIcon);
