import React, { memo } from 'react';
import { SVGProps } from 'react';

const PolygonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={15}
    fill="none"
    {...props}
  >
    <path fill="currentcolor" d="M10 7.09 0 14.172V.006l10 7.083Z" />
  </svg>
);
export default memo(PolygonIcon);
