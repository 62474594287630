import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const ReactNativePaperIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <path
      fill="#1B1B1F"
      fillRule="evenodd"
      d="m68.896 4.661-44.598 25.84-7.96 29.722 24.4-13.932.005-.003L57.918 36.4l.054-.026c6.364-3.046 13.98-1.598 17.934 4.942.475.786.87 1.64 1.195 2.509l7.123-26.466.016-.047c.496-1.467.81-6.082-3.279-10.658-1.902-2.13-4.264-2.915-6.52-3.021-2.258-.107-4.344.475-5.546 1.027Zm9.2 47.498c.08-.21.132-.424.167-.624l8.955-33.271c.825-2.515.912-8.276-3.925-13.691-2.576-2.884-5.8-3.924-8.704-4.06-2.867-.136-5.488.599-7.08 1.347l-.061.029-45.275 26.233a1.563 1.563 0 0 0-.726.947l-8.904 33.24c-.43 1.61 1.295 2.94 2.741 2.114l23.749-13.56-8.62 32.019 17.376 17.037c1.025 1.006 2.754.533 3.124-.855l8.162-30.56 9.784-5.712c2.319-1.271 4.378-3.046 5.974-4.923 1.556-1.831 2.75-3.853 3.262-5.71ZM55.242 70.74 34.714 82.723l13.733 13.465 6.796-25.448ZM34.656 79.14l8.202-30.463 16.516-9.507c5.129-2.428 10.87-1.176 13.858 3.765.89 1.47 1.476 3.378 1.774 5.085.147.845.217 1.6.224 2.184a5.294 5.294 0 0 1-.029.686l-.098.365-.001.004c-.34 1.29-1.254 2.944-2.65 4.587-1.384 1.628-3.16 3.15-5.112 4.216l-.02.01L34.657 79.14Z"
      clipRule="evenodd"
    />
  </svg>
);

export default React.memo(ReactNativePaperIcon);
