import * as React from 'react';
import './style.scss';
import Text from '../Text';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Category from '../Category';
import Breadcrumbs from '../Breadcrumbs';

interface Props {
  image: IGatsbyImageData;
  title: string;
  slug: string;
  tags: string[];
}

const ProductHeader: React.FC<Props> = ({ image, title, slug, tags }) => {
  return (
    <header>
      <div className="product__header">
        <div className="product__header__title__container">
          <Text
            type="p"
            color="dark-grey"
            className="product__header__caption p-updated"
          >
            project
          </Text>
          <Text type="h1" color="light" className="product__header__title h1">
            {title}
          </Text>
          <div className="product__header__tag-list">
            {tags.map((tag, index) => (
              <Category
                style="dark-grey"
                className="product__header__tag"
                key={index}
              >
                {tag}
              </Category>
            ))}
          </div>
        </div>
        <div className="product__header__cover">
          <Breadcrumbs
            currentPageTitle={title}
            textColor="light"
            path={`/portfolio/${slug}/`}
            className="product__header__breadcrumbs"
          />
          <GatsbyImage
            image={image}
            alt="app"
            objectFit="contain"
            className="product__header__image"
          />
        </div>
      </div>
    </header>
  );
};

export default ProductHeader;
