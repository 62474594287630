import { HeadProps, PageProps, graphql } from 'gatsby';
import React, { useMemo } from 'react';
import {
  AppProductContent,
  AppType,
  ProductContentVariants,
} from '../../utils/appTypes';
import Navigation from '../Navigation';
import ProductHeader from '../ProductHeader';
import Footer from '../Footer';
import Text from '../Text';
import { GatsbyImage } from 'gatsby-plugin-image';
import Stats from '../ProductStats';
import Stages from '../Stages';
import { convertAPIStagesToLayoutStages } from '../../utils/appHelpers';
import SEO from '../seo';
import './style.scss';
import MessageUs from '../MessageUs';
import CommentsUpdated from '../Comments/CommentsUpdated';
import BlogPostsList, { SanityArticleData } from '../BlogPostsList';
import ProductTechStack from '../ProductTechStack';
import Perspective from '../ProductPerspective';
import ProductStoreLinks from '../ProductStoreLinks';

type DataProps = {
  sanityApp: AppType;
  allSanityArticle: SanityArticleData;
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
  allSanityApp: { nodes: AppType[] };
};

const renderContentItem = (item: AppProductContent) => {
  switch (item.type) {
    case ProductContentVariants.Title: {
      return (
        <Text type="h2" className="product__title" color="dark">
          {item.children}
        </Text>
      );
    }
    case ProductContentVariants.Stages: {
      return (
        <Stages stages={convertAPIStagesToLayoutStages(item.stagesData)} />
      );
    }
    case ProductContentVariants.Stats: {
      return <Stats stats={item.statsData} />;
    }
    case ProductContentVariants.Image: {
      return (
        <div className="product__image">
          <GatsbyImage image={item.image.asset.gatsbyImageData} alt="Product" />
        </div>
      );
    }
    case ProductContentVariants.List: {
      return (
        <ul>
          {item.texts?.map((text, index) => (
            <li key={index}>
              <Text type="label" className="product__text">
                {text}
              </Text>
            </li>
          ))}
        </ul>
      );
    }
    case ProductContentVariants.Label: {
      return (
        <Text type="label" className="product__text">
          {item.children}
        </Text>
      );
    }
    case ProductContentVariants.Highlight: {
      return (
        <div className="product__highlight">
          <Text type="label" className="product__text">
            {item.children}
          </Text>
        </div>
      );
    }
    case ProductContentVariants.Perspective: {
      return <Perspective content={item.perspective} />;
    }
    default: {
      return null;
    }
  }
};

const ProductPage = ({
  data: { sanityApp, allSanityArticle },
}: PageProps<DataProps>) => {
  const {
    cover,
    title,
    tags,
    slug,
    feedback,
    blog,
    techStack,
    storeLinks,
    productContent: productContentData,
  } = sanityApp;

  const ProductContent = useMemo(
    () => productContentData.map(renderContentItem),
    [productContentData]
  );

  return (
    <div>
      <Navigation headerBackgroundColor="none" currentPage="portfolio" />
      <ProductHeader
        image={cover.asset.gatsbyImageData}
        title={title}
        tags={tags}
        slug={slug}
      />
      <article className="product__content">
        {ProductContent}
        {storeLinks && <ProductStoreLinks links={storeLinks} />}
      </article>

      <ProductTechStack techStack={techStack} />

      {blog && (
        <BlogPostsList
          title={blog.title}
          articles={allSanityArticle}
          mainArticleDescription={blog.mainArticleDescription}
          selectedArticles={blog.articles}
        />
      )}

      {feedback && (
        <CommentsUpdated
          title={feedback.title}
          commentsOrder={feedback.items}
        />
      )}

      <MessageUs />

      <Footer noFooterCard={true} isLegacy={false} />
    </div>
  );
};

export default ProductPage;

export const query = graphql`
  query ($id: String!, $language: String!) {
    sanityApp(id: { eq: $id }) {
      title
      techStack
      tags
      moto
      backgroundColor
      cover {
        asset {
          gatsbyImageData
        }
      }
      seoData {
        seoTitle
        seoDescription
      }
      productContent {
        weeks
        type
        people
        children
        texts
        commits
        image {
          asset {
            gatsbyImageData
          }
        }
        stagesData {
          title
          items
          duration
        }
        statsData {
          title
          value
        }
        perspective {
          title
          text
          clientFeedback {
            authorName
            authorPosition
            authorImage {
              asset {
                gatsbyImageData(
                  height: 72
                  width: 72
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            image {
              asset {
                gatsbyImageData(
                  height: 462
                  width: 826
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            videoLink
          }
        }
      }
      blog {
        title
        mainArticleDescription
        articles {
          slug
          readTime
        }
      }
      feedback {
        title
        items
      }
      storeLinks {
        playStore
        appStore
      }
    }
    allSanityArticle {
      nodes {
        slug {
          current
        }
        tags
        title
        _updatedAt
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data: { sanityApp } }: HeadProps<DataProps>) => (
  <SEO
    title={sanityApp.seoData.seoTitle}
    description={sanityApp.seoData.seoDescription}
  />
);
