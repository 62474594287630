import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const DigitalOceanIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g fill="#1B1B1F" clipPath="url(#a)">
      <path d="M49.758 100.459v-19.44c20.61 0 36.509-20.32 28.563-41.823a28.97 28.97 0 0 0-17.37-17.37 30.708 30.708 0 0 0-41.517 28.562H0C0 17.988 31.507-7.636 65.952 2.97A48.145 48.145 0 0 1 97.46 34.483c10.601 34.464-15.017 65.976-47.7 65.976Z" />
      <path d="M50.054 81.02H30.62V61.876h19.434M30.62 96.043H15.898V81.02H30.62v15.023ZM15.898 81.02H3.548V68.65h12.35" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(DigitalOceanIcon);
