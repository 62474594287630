import * as React from 'react';

import { TechIconItemProps } from '../TechIcon';

const GRPCIcon = (props: TechIconItemProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 101"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#1B1B1F"
        fillRule="evenodd"
        d="M31.91 57v19.469c0 4.051.688 7.573 2.068 10.565 1.38 2.993 3.194 5.478 5.445 7.458a23.474 23.474 0 0 0 7.583 4.49c2.802 1.012 5.632 1.518 8.478 1.518 2.847 0 5.675-.506 8.478-1.519a23.456 23.456 0 0 0 7.583-4.489c2.252-1.98 4.067-4.465 5.446-7.458 1.378-2.992 2.067-6.515 2.067-10.565V44.03l-25.197-.039L53.83 57h11.167v19.469c0 3.406-.942 5.915-2.813 7.528-1.87 1.612-4.112 2.417-6.687 2.417s-4.802-.806-6.688-2.417c-1.886-1.611-2.812-4.12-2.812-7.528V24.53c0-3.406.942-5.915 2.812-7.528 1.87-1.612 4.113-2.417 6.688-2.417 2.575 0 4.801.806 6.687 2.417 1.886 1.611 2.813 4.122 2.813 7.528v4.972h14.062v-4.972c0-3.96-.687-7.458-2.067-10.497-1.38-3.039-3.194-5.546-5.445-7.528a23.518 23.518 0 0 0-7.581-4.49C61.166 1 58.333.5 55.484.5c-2.848 0-5.675.506-8.478 1.519a23.46 23.46 0 0 0-7.583 4.489c-2.251 1.98-4.067 4.489-5.445 7.528-1.378 3.039-2.067 6.537-2.067 10.497v19.469H17.525v-14.5h4.413l-10.97-15.5L0 29.502h4.563V50.5c0 3.59 2.901 6.5 6.484 6.5h20.867-.003Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(GRPCIcon);
